/* src/App.css */
.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
}

.App-header {
  background-color: #282c34;
  padding: 1rem;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position : fixed;
  width: 100%;
}

.App-header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.App-main {
  flex: 1;
  position: relative;
  overflow: hidden;
}

/* Graph container takes full size of main area */
.App-main > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Canvas styling */
canvas {
  border-radius: 4px;
}

/* Ensure the visualization container fills available space */
.force-graph-container {
  width: 100% !important;
  height: 100% !important;
  background-color: #1a1a1a;
}

.navigation-button {
  padding: "5px";
  border-radius: 4px;
  border: "none";
  cursor: "pointer";
  font-size: "14px";
  height: 30px;
  border:none;
}